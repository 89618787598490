import React from "react";

function CallToActions() {
  return (
    <div>
      <section
        id="call-to-action"
        className="call-to-action section dark-background"
      >
        <img src="assets/img/cta-bg.jpg" alt="" />

        <div className="container">
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-xl-9 text-center text-xl-start">
              <h3>Call To Action</h3>
              <p>
                Ready to connect with top talent or explore our innovative
                solutions? Take the next step and reach out to Operating
                Software today—let’s create something extraordinary together!
              </p>
            </div>
            <div className="col-xl-3 cta-btn-container text-center">
              <a className="cta-btn align-middle" href="#contact">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CallToActions;
