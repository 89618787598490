import React from "react";

function AboutUs() {
  return (
    <div>
      <section id="about" className="about section">
        <div className="container section-title" data-aos="fade-up">
          <h2>About Us</h2>
        </div>

        <div className="container">
          <div className="row gy-4">
            <div
              className="col-lg-6 content"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <p>
                <strong>Operating Software</strong> specializes in developing
                innovative solutions that connect skilled professionals with
                businesses, driving success and growth
              </p>
              <ul>
                <li>
                  <i className="bi bi-check2-circle"></i>
                  <span>
                    Empowering Talent: We create opportunities for skilled
                    professionals to showcase their expertise and thrive in
                    their careers.
                  </span>
                </li>
                <li>
                  <i className="bi bi-check2-circle"></i>
                  <span>
                    Bridging Gaps: Our solutions seamlessly link businesses with
                    the right talent, ensuring the perfect match for every
                    project.
                  </span>
                </li>
                <li>
                  <i className="bi bi-check2-circle"></i>
                  <span>
                    Driving Innovation: We are committed to delivering
                    cutting-edge software that tackles complex challenges and
                    fuels business success.
                  </span>
                </li>
              </ul>
            </div>

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
              <p>
                <strong>Operating Software</strong> is a tech company dedicated
                to developing innovative solutions that address real-world
                challenges. We empower skilled professionals by creating
                opportunities and seamlessly connect them with customers in need
                of their expertise. Our mission is to harness technology to
                bridge gaps, foster growth, and drive success for businesses and
                individuals alike.
              </p>
              <a href="#contact" className="read-more">
                <span>Contact Us</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
