import React, { useState } from "react";

function FQA() {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqData = [
    {
      question: "What services does Operating Software offer?",
      answer:
        "We offer a range of innovative software solutions that connect skilled professionals with businesses, including products like Mapishi Chefs, which links talented chefs with customers.",
    },
    {
      question: "How does Mapishi Chefs work?",
      answer:
        "Mapishi Chefs is a platform that connects chefs with customers looking for personalized culinary experiences. Customers can browse profiles, view specialties, and book the right chef for their needs.",
    },
    {
      question: "How can I get in touch with your team?",
      answer:
        "You can contact us directly through our website’s Contact page. We’re available to answer your questions, provide support, and discuss partnership opportunities.",
    },
    {
      question:
        "What makes Operating Software different from other tech companies?",
      answer:
        "We specialize in creating customized solutions that address real-world challenges, focusing on connecting talent with opportunities. Our commitment to quality, innovation, and customer satisfaction sets us apart.",
    },
    {
      question: "How can I stay updated on new products and services?",
      answer:
        "Follow us on our social media channels and subscribe to our newsletter to receive the latest updates, news, and insights from Operating Software.",
    },
  ];
  function FAQItem({ question, answer, isActive, onToggle }) {
    return (
      <div
        className={`faq-item ${isActive ? "faq-active" : ""}`}
        onClick={onToggle}
      >
        <i className="faq-icon bi bi-question-circle"></i>
        <h3>{question}</h3>
        <div className="faq-content">
          <p>{answer}</p>
        </div>
        <i className="faq-toggle bi bi-chevron-right"></i>
      </div>
    );
  }
  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      <section id="faq-2" className="faq-2 section light-background">
        <div className="container section-title" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
          <p>
            Find answers to common questions and gain insights into our products
            and services. Our FAQ section is here to help you understand more
            about what we offer and how we can assist you.
          </p>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="faq-container">
                {faqData.map((item, index) => (
                  <FAQItem
                    key={index}
                    question={item.question}
                    answer={item.answer}
                    isActive={activeIndex === index}
                    onToggle={() => handleToggle(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FQA;
