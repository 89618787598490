import React, { useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import HeroSection from "../components/HeroSection";
import ClientsSection from "../components/ClientsSection";
import AboutUs from "../components/AboutUs";
import WhyUs from "../components/WhyUs";
import Services from "../components/Services";
import CallToActions from "../components/CallToActions";
import PortFolio from "../components/PortFolio";
import TeamMembers from "../components/TeamMembers";
import Pricing from "../components/Pricing";
import Testimonials from "../components/Testimonials";
import FQA from "../components/FQA";
import ContactUs from "../components/ContactUs";
import SiteFooter from "../components/SiteFooter";

function LandingPage() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/landingpage.js";
    script.async = true; // Ensures the script loads asynchronously
    document.body.appendChild(script); // Append the script to the body

    return () => {
      document.body.removeChild(script); // Clean up by removing the script when component unmounts
    };
  }, []);
  const clients = [
    { image: "assets/img/clients/client-1.png", altText: "Client 1" },
    { image: "assets/img/clients/client-2.png", altText: "Client 2" },
    { image: "assets/img/clients/client-3.png", altText: "Client 3" },
    { image: "assets/img/clients/client-4.png", altText: "Client 4" },
    { image: "assets/img/clients/client-5.png", altText: "Client 5" },
    { image: "assets/img/clients/client-6.png", altText: "Client 6" },
    { image: "assets/img/clients/client-7.png", altText: "Client 7" },
    { image: "assets/img/clients/client-8.png", altText: "Client 8" },
  ];
  return (
    <div>
      <HeaderComponent />
      <HeroSection />
      {/* <ClientsSection clients={clients} /> */}
      <AboutUs />
      <WhyUs />
      <Services />
      <CallToActions />
      {/* <PortFolio /> */}
      <TeamMembers />
      {/* <Pricing /> */}
      <Testimonials />
      <FQA />
      <ContactUs />
      <SiteFooter />
    </div>
  );
}

export default LandingPage;
