import React from "react";

function TeamMembers() {
  function MemberItem({ image, name, title, description, socialLinks, delay }) {
    return (
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={delay}>
        <div className="team-member d-flex align-items-start">
          <div className="pic">
            <img src={image} className="img-fluid" alt={name} />
          </div>
          <div className="member-info">
            <h4>{name}</h4>
            <span>{title}</span>
            <p>{description}</p>
            <div className="social">
              {socialLinks.map((link, index) => (
                <a href={link.url} key={index}>
                  <i className={`bi ${link.icon}`}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  const teamData = [
    {
      image: "assets/img/team/osodo.jpg",
      name: "Peter Osodo Wanga",
      title: "Founder/Software Engineer",
      description:
        "Transforming vision into reality through passion and precision.",
      socialLinks: [
        { url: "https://x.com/Ptahosodo", icon: "bi-twitter-x" },
        // { url: "/", icon: "bi-facebook" },
        // { url: "/", icon: "bi-instagram" },
        {
          url: "https://www.linkedin.com/in/peter-osodo-4a47631a7/",
          icon: "bi-linkedin",
        },
      ],
      delay: "100",
    },
    {
      image: "assets/img/team/lilly.jpg",
      name: "Lilian Rotich Chebichii",
      title: "Business Analysit",
      description:
        "Turning data into actionable insights to drive strategic success and optimize business performance.",
      socialLinks: [
        { url: "/", icon: "bi-twitter-x" },
        { url: "/", icon: "bi-facebook" },
        { url: "/", icon: "bi-instagram" },
        { url: "/", icon: "bi-linkedin" },
      ],
      delay: "200",
    },
    // {
    //   image: "assets/img/team/team-3.jpg",
    //   name: "William Anderson",
    //   title: "CTO",
    //   description:
    //     "Quisquam facilis cum velit laborum corrupti fuga rerum quia",
    //   socialLinks: [
    //     { url: "/", icon: "bi-twitter-x" },
    //     { url: "/", icon: "bi-facebook" },
    //     { url: "/", icon: "bi-instagram" },
    //     { url: "/", icon: "bi-linkedin" },
    //   ],
    //   delay: "300",
    // },
    // {
    //   image: "assets/img/team/team-4.jpg",
    //   name: "Amanda Jepson",
    //   title: "Accountant",
    //   description:
    //     "Dolorum tempora officiis odit laborum officiis et et accusamus",
    //   socialLinks: [
    //     { url: "/", icon: "bi-twitter-x" },
    //     { url: "/", icon: "bi-facebook" },
    //     { url: "/", icon: "bi-instagram" },
    //     { url: "/", icon: "bi-linkedin" },
    //   ],
    //   delay: "400",
    // },
  ];

  return (
    <div>
      <section id="team" className="team section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Team</h2>
          <p>
            Meet the talented team behind Operating Software, a diverse group of
            professionals dedicated to innovation, collaboration, and delivering
            exceptional results. Each member brings unique expertise and
            passion, working together to turn challenges into opportunities and
            drive success for our clients.
          </p>
        </div>

        <div className="container">
          <div className="row gy-4">
            {teamData.map((member, index) => (
              <MemberItem
                key={index}
                image={member.image}
                name={member.name}
                title={member.title}
                description={member.description}
                socialLinks={member.socialLinks}
                delay={member.delay}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TeamMembers;
