import React, { useState } from "react";

function FAQItem({ number, question, answer, isActive, onClick }) {
  return (
    <div
      className={`faq-item ${isActive ? "faq-active" : ""}`}
      onClick={onClick}
    >
      <h3>
        <span>{number}</span> {question}
      </h3>
      <div className="faq-content">
        <p>{answer}</p>
      </div>
      <i className="faq-toggle bi bi-chevron-right"></i>
    </div>
  );
}

function WhyUs() {
  // Initialize the state to keep track of the active FAQ item
  const [activeIndex, setActiveIndex] = useState(0);

  // FAQ data
  const faqData = [
    {
      number: "01",
      question: "Expertise and Innovation",
      answer:
        "At Operating Software, we combine deep industry knowledge with innovative thinking to deliver solutions that are not just effective but also future-proof. Our team of skilled professionals is dedicated to pushing the boundaries of what technology can achieve.",
    },
    {
      number: "02",
      question: "Tailored Solutions",
      answer:
        "We understand that every business is unique, and so are its challenges. That's why we offer customized software solutions that are designed to meet your specific needs, ensuring optimal results and seamless integration.",
    },
    {
      number: "03",
      question: "Commitment to Quality",
      answer:
        "Quality is at the heart of everything we do. From the initial concept to the final product, we maintain the highest standards, ensuring that our solutions are reliable, efficient, and built to last.",
    },
    {
      number: "04",
      question: "Customer-Centric Approach",
      answer:
        "Your success is our priority. We work closely with you throughout the entire process, providing ongoing support and ensuring that our solutions align with your goals and expectations.",
    },
  ];

  // Handle click event to toggle active FAQ item
  const handleFAQClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle active state
  };

  return (
    <div>
      <section
        id="why-us"
        className="section why-us light-background"
        data-builder="section"
      >
        <div className="container-fluid">
          <div className="row gy-4">
            <div className="col-lg-7 d-flex flex-column justify-content-center order-2 order-lg-1">
              <div
                className="content px-xl-5"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3>
                  <span> the key advantages that make</span>
                  <strong> Operating Software</strong>{" "}
                  <span>your ideal tech partner:</span>
                </h3>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Duis aute irure dolor in reprehenderit
                </p> */}
              </div>

              <div
                className="faq-container px-xl-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {faqData.map((item, index) => (
                  <FAQItem
                    key={index}
                    number={item.number}
                    question={item.question}
                    answer={item.answer}
                    isActive={activeIndex === index}
                    onClick={() => handleFAQClick(index)}
                  />
                ))}
              </div>
            </div>

            <div className="col-lg-5 order-1 order-lg-2 why-us-img">
              <img
                src="assets/img/why-us.png"
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="100"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyUs;
