import React from "react";

function Services() {
  // Array of service data
  const servicesData = [
    {
      iconClass: "bi-activity",
      title: "Mapishi Chefs",
      description:
        "Mapishi Chefs connects talented chefs with customers seeking exceptional culinary experiences, making it easy to find and hire top talent for any occasion.",
      delay: "100",
      imageUrl: "assets/img/mapishi.png",
      siteURl: "https://mapishichefs.com/",
    },
    // {
    //   iconClass: "bi-bounding-box-circles",
    //   title: "Sed ut perspici",
    //   description:
    //     "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
    //   delay: "200",
    //   imageUrl: "",
    //   siteURl: "/",
    // },
    // {
    //   iconClass: "bi-calendar4-week",
    //   title: "Magni Dolores",
    //   description:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia",
    //   delay: "300",
    //   imageUrl: "",
    //   siteURl: "/",
    // },
    // {
    //   iconClass: "bi-broadcast",
    //   title: "Nemo Enim",
    //   description:
    //     "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis",
    //   delay: "400",
    //   imageUrl: "",
    //   siteURl: "/",
    // },
  ];
  function ServiceItem({
    iconClass,
    title,
    description,
    delay,
    imageUrl,
    siteURl,
  }) {
    return (
      <div
        className="col-xl-3 col-md-6 d-flex"
        data-aos="fade-up"
        data-aos-delay={delay}
      >
        <div className="service-item position-relative">
          <div className="icon">
            <img
              src={imageUrl || "assets/img/logo.jpeg"}
              alt="Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
          <h4>
            <a href={siteURl} className="stretched-link">
              {title}
            </a>
          </h4>
          <p>{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section id="services" className="services section light-background">
        <div className="container section-title" data-aos="fade-up">
          <h2>Products</h2>
          <p>
            Explore our range of innovative products designed to connect talent
            with opportunity and provide tailored solutions for your needs.
          </p>
        </div>

        <div className="container">
          <div className="row gy-4">
            {servicesData.map((service, index) => (
              <ServiceItem
                key={index}
                iconClass={service.iconClass}
                title={service.title}
                description={service.description}
                delay={service.delay}
                imageUrl={service.imageUrl}
                siteURl={service.siteURl}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
