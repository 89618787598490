import React from "react";

function SiteFooter() {
  const usefulLinks = [
    { text: "Home", href: "/" },
    { text: "About us", href: "#about" },
    { text: "Products", href: "#services" },
    // { text: "Terms of service", href: "/" }, // Uncomment if needed
  ];

  const servicesLinks = [
    { text: "Mapishi Chefs", href: "https://mapishichefs.com/" },
  ];

  function FooterLinks({ title, links }) {
    return (
      <div className="footer-links">
        <h4>{title}</h4>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <i className="bi bi-chevron-right"></i>
              <a href={link.href}>{link.text} </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function ProductLinks({ title, links }) {
    return (
      <div className="footer-links">
        <h4>{title}</h4>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <i className="bi bi-chevron-right"></i>
              <a href={link.href} target="_blank" rel="noopener noreferrer">
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return (
    <footer id="footer" className="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6">
              <h4>Join Our Newsletter</h4>
              <p>
                Subscribe to our newsletter and receive the latest news about
                our products and services!
              </p>
              <form
                action="forms/newsletter.php"
                method="post"
                className="php-email-form"
              >
                <div className="newsletter-form">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </div>
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your subscription request has been sent. Thank you!
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 footer-about">
            <a href="index.html" className="d-flex align-items-center">
              <span className="sitename">OS Ltd</span>
            </a>
            <div className="footer-contact pt-3">
              <p>Thika - Mang'u Road</p>
              <p>Thika West Center</p>
              <p className="mt-3">
                <strong>Phone:</strong> <span>+254 791 335 490</span>
              </p>
              <p>
                <strong>Email:</strong>
                <span>info.operatingsoftware@gmail.com</span>
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-md-3">
            <FooterLinks title="Useful Links" links={usefulLinks} />
          </div>

          <div className="col-lg-2 col-md-3">
            <ProductLinks title="Our Products" links={servicesLinks} />
          </div>

          <div className="col-lg-4 col-md-12">
            <h4>Follow Us</h4>
            <p>
              Stay connected and keep up with the latest updates, news, and
              insights from Operating Software. Follow us on our social media
              channels to join our growing community and be part of our journey.
            </p>
            <div className="social-links d-flex">
              <a href="/">
                <i className="bi bi-twitter-x"></i>
              </a>
              <a href="/">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="/">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="/">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>
          <strong className="px-1 sitename">Operating Software</strong>
          <span>All Rights Reserved</span>
        </p>
        {/* <div className="credits">
          Designed by <a href="https://bootstrapmade.com/">OS</a>
        </div> */}
      </div>
    </footer>
  );
}

export default SiteFooter;
